
import { defineComponent, ref, onMounted } from "vue";
import { CreateOrEditCustomerDto } from "@/shared/service-proxies/generated-proxies";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import useCustomers from "@/composables/useCustomers";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import CustomerForm from "@/components/Forms/CustomerForm.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  components: {
    CustomerForm,
  },
  setup() {
    const route = useRoute();
    const loaded = ref(false);

    const { updateOrCreateCustomer, getCustomer, customer } = useCustomers();
    const store = useStore();

    const saving = ref(false);

    const submit = async () => {
      saving.value = true;
      await updateOrCreateCustomer(
        customer.value.customer as unknown as CreateOrEditCustomerDto
      );
      const error = store.getters.getErrors;
      saving.value = false;
      if (!error) {
        Swal.fire({
          text: "Customer updated successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Edit Customers", ["Customers", "Edit"]);
      await getCustomer(route.params.id as unknown as string);
      loaded.value = true;
    });

    return {
      customer,
      saving,
      submit,
      loaded,
    };
  },
});
