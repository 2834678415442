import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_customer_form = _resolveComponent("customer-form")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_customer_form, {
        key: 0,
        modelValue: _ctx.customer.customer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.customer) = $event)),
        onSubmit: _ctx.submit,
        saving: _ctx.saving
      }, null, 8, ["modelValue", "onSubmit", "saving"]))
    : _createCommentVNode("", true)
}